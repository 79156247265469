import { combineReducers } from "redux";
import mainStore from "./mainReducer";
import authStore from "./authReducer";
import dashboardStore from "./dashboardReducer";
import usersStore from "./usersReducer";
import notificationsStore from "./notificationsReducer";
import settingsStore from "./settingsReducer";

const rootReducer = combineReducers({
  mainStore,
  authStore,
  dashboardStore,
  usersStore,
  notificationsStore,
  settingsStore,
});

export default rootReducer;
