import { CLEAR_STORE } from "../constants/mainConstants";
import { SET_NOTIFICATIONS } from "../constants/notificationsConstants";

const initialState = {
  notifications: [],
};

const notificationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };

    case CLEAR_STORE:
      return initialState;

    default:
      return state;
  }
};

export default notificationsReducer;
