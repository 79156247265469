import { Button, Input, Row, Space, Table } from "antd";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { IUser } from "../../types";
import { getUsers, searchUsers } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";
import type { ColumnsType, SortOrder } from "antd/es/table/interface";
import CheckCircleTwoTone from "@ant-design/icons/lib/icons/CheckCircleTwoTone";

const Users = () => {
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    offset: 0,
    rowsPerPage: 10,
  });
  const messageContext = useContext(MessageContext);
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortOrderColumns, setsortOrderColumns] = useState<
    Record<string, SortOrder>
  >({
    verified: null,
    hypelists: null,
    createdAt: "descend",
  });

  const [paginationChange, setPaginationChange] = useState({
    current: 1,
    pageSize: 10,
  });

  const handleChange = (paginationChange: any, _filters: any, sorter: any) => {
    // This will change the current direction of sorting shown on the columns
    const sorterValue = sorter.order === undefined ? null : sorter.order;
    setsortOrderColumns({ [sorter.field]: sorterValue });
    const targetCol = columns.find((item) => item.key === sorter.columnKey);
    if (targetCol) {
      targetCol.sortOrder = sorterValue;
    }
    setPaginationChange({
      current: paginationChange.current,
      pageSize: paginationChange.pageSize,
    });
  };

  useEffect(() => {
    fetchUsers((paginationChange.current - 1) * paginationChange.pageSize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrderColumns, paginationChange.current, paginationChange.pageSize]);

  const columns: ColumnsType<IUser> = [
    {
      title: "Username",
      key: "username",
      render: (_: any, record: IUser) => (
        <Link to={`/users/${record.id}`}>{record.username}</Link>
      ),
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      sorter: true,
      render: (_: any, record: IUser) => (
        <span>{record.verified ? <CheckCircleTwoTone /> : ""}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Hypelists",
      dataIndex: "hypelists",
      key: "hypelists",
      sorter: true,
      defaultSortOrder: null,
    },
    {
      title: "Referrals",
      dataIndex: "referrals",
      key: "referrals",
      sorter: true,
      defaultSortOrder: null,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      defaultSortOrder: "descend",
    },
  ];

  useEffect(() => {
    fetchUsers(pagination.offset);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async (offset: number = 0) => {
    setIsLoading(true);

    try {
      const users = await getUsers(offset, sortOrderColumns);
      setUsersList(users.data.data.data);

      setPagination({
        ...pagination,
        total: users.data.data.total,
        offset: offset,
        currentPage: offset / pagination.rowsPerPage + 1 || 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const viewUser = (user: IUser) => {
    navigate(`/users/${user.id}`);
  };

  const onSearch = async (value: string) => {
    if (!value) {
      return;
    }

    setIsLoading(true);
    try {
      const foundedUsers = await searchUsers(value);
      setUsersList(foundedUsers.data.data);

      setPagination({
        ...pagination,
        total: foundedUsers.data.data.length,
        offset: 0,
        currentPage: 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const resetSearch = () => {
    fetchUsers();
    setSearchValue("");
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="users">
      <Row>
        <h1>Users</h1>
      </Row>
      <Row>
        <Space direction="horizontal">
          <Input.Search
            size="large"
            placeholder="Search..."
            onSearch={onSearch}
            loading={isLoading}
            value={searchValue}
            onChange={onChangeSearch}
          />
          <Button size="large" onClick={resetSearch}>
            Reset
          </Button>
        </Space>

        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                viewUser(record);
              },
            };
          }}
          onChange={handleChange}
          rowKey="id"
          columns={columns}
          dataSource={usersList}
          pagination={{
            current: pagination.currentPage,
            total: pagination.total,
            showSizeChanger: false,
            position: ["bottomRight"],
          }}
          loading={isLoading}
        />
      </Row>
    </div>
  );
};

export default Users;
