import { LeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Switch, Tooltip } from "antd";
import { ChangeEvent, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { setLoader } from "../../store/actions/mainActions";
import { ISignupData } from "../../types";
import { signUp } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";
import { phoneRegexp } from "../../utils/constants";

const CreateAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const [adminData, setAdminData] = useState<ISignupData>({
    username: "",
    password: "",
    email: "",
    phone: "",
    notifications: true,
  });
  const [form] = Form.useForm();

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setAdminData({
      ...adminData,
      [name]: event.target.value,
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const createAdmin = async () => {
    dispatch(setLoader(true));

    try {
      await signUp(adminData);
      goBack();
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const onChangeSwitch = (value: boolean) => {
    setAdminData((prev) => {
      return {
        ...prev,
        notifications: value,
      };
    });
  };

  return (
    <div className="admin">
      <Row className="title">
        <Tooltip title="Back">
          <Button onClick={goBack} icon={<LeftOutlined />} />
        </Tooltip>

        <h1>Add admin</h1>
      </Row>

      <Row>
        <Form
          initialValues={adminData}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          name="control-hooks"
          onFinish={createAdmin}
        >
          <Form.Item name="username" label="Username">
            <Input value={adminData.username} onChange={onChange("username")} />
          </Form.Item>

          <Form.Item label="Password" name="password">
            <Input.Password
              value={adminData.password}
              onChange={onChange("password")}
            />
          </Form.Item>

          <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
            <Input value={adminData.email} onChange={onChange("email")} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                type: "string",
                pattern: phoneRegexp,
                message: "Wrong phone format",
              },
            ]}
          >
            <Input value={adminData.phone} onChange={onChange("phone")} />
          </Form.Item>

          <Form.Item label="Notifications" valuePropName="checked">
            <Switch
              checked={adminData.notifications}
              onChange={onChangeSwitch}
            />
          </Form.Item>

          <Form.Item
            className="btn-container"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </div>
  );
};

export default CreateAdmin;
