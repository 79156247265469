import { CLEAR_STORE, SET_LOADER } from "../constants/mainConstants";

const initialState = {
  isLoading: false,
};

const mainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case CLEAR_STORE:
      return initialState;

    default:
      return state;
  }
};

export default mainReducer;
