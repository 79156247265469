import { CLEAR_STORE } from "../constants/mainConstants";
import { SET_SETTINGS } from "../constants/settingsConstants";

const initialState = {
  settings: {},
};

const settingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };

    case CLEAR_STORE:
      return initialState;

    default:
      return state;
  }
};

export default settingsReducer;
