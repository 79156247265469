import { MessageInstance } from "antd/es/message/interface";
import { PropsWithChildren, useState, createContext } from "react";

export interface IMessageContext {
  instance: null | MessageInstance;
  setInstance: (instance: MessageInstance) => void;
}

export const MessageContext = createContext<IMessageContext>({
  instance: null,
  setInstance: () => {},
});

export const MessageContextProvider = (props: PropsWithChildren) => {
  const setInstance = (instance: MessageInstance) => {
    setState({ ...state, instance: instance });
  };

  const initState = {
    instance: null,
    setInstance: setInstance,
  };

  const [state, setState] = useState<IMessageContext>(initState);

  return (
    <MessageContext.Provider value={state}>
      {props.children}
    </MessageContext.Provider>
  );
};
