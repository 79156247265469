import { Button, Input, Row, Space, Table } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { MessageContext } from "../../context/messageContext";
import { IUserInvitation } from "../../types";
import parseErrors from "../../utils/parseErrors";
import { getAllInvitations, searchInvitations, addInvitation } from "../../utils/api";
const voucher_codes = require('voucher-code-generator');

const Invitations = () => {
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        offset: 0,
        rowsPerPage: 10,
    });
    const messageContext = useContext(MessageContext);
    const [invitationsList, setInvitationsList] = useState<IUserInvitation[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "createdAt",
        },
    ];

    useEffect(() => {
        fetchInvitations(pagination.offset);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchInvitations = async (offset: number = 0) => {
        setIsLoading(true);

        try {
          const invitations = await getAllInvitations(offset);
          setInvitationsList(invitations.data.data.data);

          setPagination({
              ...pagination,
              total: invitations.data.data.total,
              offset: offset,
              currentPage: offset / pagination.rowsPerPage + 1 || 1,
          });
        } catch (error: any) {
          const errors = parseErrors(error.response?.data?.error);

          for (let i = 0; i < errors.length; i += 1) {
              messageContext.instance?.open({
              type: "error",
              content: errors[i],
              });
          }
        }

        setIsLoading(false);
    };

    const onPaginationChange = (page: number) => {
        fetchInvitations(page * pagination.rowsPerPage - pagination.rowsPerPage);
    };

    const onSearch = async (value: string) => {
    if (!value) {
      return;
    }

    setIsLoading(true);
    try {
      const searchResults = await searchInvitations(value);
      setInvitationsList(searchResults.data.data.data);

      setPagination({
        ...pagination,
        total: searchResults.data.data.total,
        offset: 0,
        currentPage: 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

    const resetSearch = () => {
        fetchInvitations();
        setSearchValue("");
    };

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    };

    const createInvitation = async () => {
      await addInvitation({ code: voucher_codes.generate({ length: 6 })[0].toUpperCase() });
      fetchInvitations();
    };

    return (
    <div className="users">
      <Row>
        <h1>Invitations</h1>
      </Row>
      <Row>
        <Button type="primary" onClick={createInvitation}>
          <PlusCircleOutlined />
          Add
        </Button>
      </Row>
      <Row>
        <Space direction="horizontal">
          <Input.Search
            size="large"
            placeholder="Search..."
            onSearch={onSearch}
            loading={isLoading}
            value={searchValue}
            onChange={onChangeSearch}
          />
          <Button size="large" onClick={resetSearch}>
            Reset
          </Button>
        </Space>

        <Table
          rowKey="id"
          columns={columns}
          dataSource={invitationsList}
          pagination={{
            current: pagination.currentPage,
            total: pagination.total,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
          loading={isLoading}
        />
      </Row>
    </div>
  );
};

export default Invitations;
