import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Row } from "antd";
import { useState } from "react";
import HypelistSearchBar from "../../components/HypelistSearchBar";

export default function CreateHighlight({
  handleCreateHighlight,
}: {
  handleCreateHighlight: (payload: any) => void;
}) {
  const [open, setOpen] = useState(false);
  const [selectedHypelistId, setSelectedHypelistId] = useState<string | null>();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleVideoChange = (event: any) => {
    setSelectedVideo(event.target.files[0]);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onFinish = async (values: any) => {
    handleCreateHighlight({
      hypelistId: selectedHypelistId,
      ...values,
      file: selectedFile,
      video: selectedVideo,
    });
    setOpen(false);
  };

  const onFinishFailed = () => {
    setOpen(false);
    alert("Missing fields");
  };

  const onHypelistSelect = (hypelistId: string, hypelistName: string) => {
    setSelectedHypelistId(hypelistId);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <PlusCircleOutlined />
        New Highlight
      </Button>
      <Modal
        title="Create Highlight"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div style={{ display: "flex" }}></div>
        <br />
        <Form
          //   labelCol={{ span: 8 }}
          //   wrapperCol={{ span: 16 }}
          //   style={{ maxWidth: 600 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="highlightTitle"
            rules={[{ required: true, message: "Please input list title" }]}
          >
            <Input placeholder="Highlight Title" />
          </Form.Item>
          <Form.Item name="highlightSubtitle">
            <Input placeholder="Highlight Subtitle" />
          </Form.Item>
          <Form.Item name="externalLinkUrl">
            <Input placeholder="External URL" />
          </Form.Item>
          <Form.Item name="externalLinkTitle">
            <Input placeholder="External URL Text" />
          </Form.Item>
          <h3>Choose One Highlight Media Type:</h3>
          <h4>Background Image:</h4>
          <div>
            This image will be replaced by a thumbnail of the first frame of a
            video, if chosen. It's worth uploading one, in case video
            transcoding fails.
          </div>
          <Input
            type="file"
            placeholder="Background Image"
            onChange={handleFileChange}
          />
          <br />
          <h4>Video &lt;10s:</h4>
          <Input
            type="file"
            placeholder="Add video (<10s)"
            onChange={handleVideoChange}
          />
          <br />
          <h4>Link a Hypelist:</h4>
          <HypelistSearchBar onSelect={onHypelistSelect} />
          <br />
          {selectedHypelistId && (
            <Row>
              <div>{selectedHypelistId}</div>&nbsp;
              <Button
                type="primary"
                htmlType="button"
                onClick={() => setSelectedHypelistId(null)}
                title="Clear"
              >
                Clear
              </Button>
            </Row>
          )}
          <br />
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
