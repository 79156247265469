import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Row, Table } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { IAdmin } from "../../types";
import { getAdmins } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";

const Admins = () => {
  const messageContext = useContext(MessageContext);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    offset: 0,
    rowsPerPage: 10,
  });
  const [adminsList, setAdminsList] = useState<IAdmin[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async (offset: number = pagination.offset) => {
    setIsLoading(true);

    try {
      const users = await getAdmins(offset);
      setAdminsList(users.data.data.data);

      setPagination({
        ...pagination,
        total: users.data.data.total,
        offset: offset,
        currentPage: offset / pagination.rowsPerPage + 1 || 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const onPaginationChange = (page: number) => {
    fetchAdmins(page * pagination.rowsPerPage - pagination.rowsPerPage);
  };

  const viewUser = (user: IAdmin) => {
    navigate(`/admins/${user.id}`);
  };

  const addAdmin = () => {
    navigate(`/admins/add`);
  };

  return (
    <div className="admins">
      <Row>
        <h1>Admins</h1>
      </Row>

      <Row>
        <Row className="btn-container">
          <Button type="primary" onClick={addAdmin}>
            <PlusCircleOutlined />
            Add
          </Button>
        </Row>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                viewUser(record);
              },
            };
          }}
          rowKey="id"
          columns={columns}
          dataSource={adminsList}
          pagination={{
            current: pagination.currentPage,
            total: pagination.total,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
          loading={isLoading}
        />
      </Row>
    </div>
  );
};

export default Admins;
