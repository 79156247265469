import { useState, useContext, useEffect } from "react";
import { MessageContext } from "../../context/messageContext";
import { searchAllHypelists } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";
import { AutoComplete, Spin } from "antd";
import { useDebounce } from "use-debounce";

const HypelistSearchBar = ({
  onSelect
} : {
  onSelect: (hypelistId: string, hypelistName: string) => void;
}
) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [hypelistResults, setHypelistResults] = useState<{value:string, label:string}[]>([]);
  const messageContext = useContext(MessageContext);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue] = useDebounce(inputValue, 800);

  const handleInputChange = (value: any) => {
    setInputValue(value);
 }

  const onClick = (data: any, options: any) => {
    onSelect(options.value, options.label)
  };

  const searchHypelists = async (value: string) => {
    if (!value) {
      return;
    }
    if (value.length < 3) {
      return;
    }
    if (searchLoading) {
      return;
    }
    try {
      setSearchLoading(true);
      const allHypelists = await searchAllHypelists(value);
      setHypelistResults(allHypelists.data.data.map((h: { id: any; title: any; userName: string; }) => {
        return {
          value: h.id,
          label: `${h.title} by ${h.userName}`
        }
      }));
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    searchHypelists(debouncedValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div>
      <AutoComplete
          allowClear
          disabled={searchLoading}
          options={hypelistResults}
          style={{ width: 200 }}
          onSelect={onClick}
          onChange={handleInputChange}
          placeholder="Search by hypelist name..."
        />
          {searchLoading && <Spin />}
    </div>
  );
};

export default HypelistSearchBar;
