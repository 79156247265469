import { CLEAR_STORE, SET_LOADER } from "../constants/mainConstants";

export const clearStore = () => {
  return {
    type: CLEAR_STORE,
  };
};

export const setLoader = (payload: boolean) => {
  return {
    type: SET_LOADER,
    payload,
  };
};
