import { useEffect, useContext, useState } from "react";

import { Row, Button, Col, Empty } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { MessageContext } from "../../context/messageContext";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/actions/mainActions";
import parseErrors from "../../utils/parseErrors";
import Person from './Person';
import { addFollowPeople, getFollowPeople, removeFollowPeople } from "../../utils/api";
import { IFollowPeople } from "../../types";
import SearchSection from "./FollowPeopleSearch";


export default function FollowPeople() {
    const dispatch = useDispatch();
    const messageContext = useContext(MessageContext);
    
    const [followPeopleList, setFollowPeopleList] = useState<IFollowPeople[]>([]);
    const [searchVisible, setSearchVisible] = useState<Boolean>(false);

    useEffect(() => {
        fetchFollowPeople();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchFollowPeople = async () => {
        dispatch(setLoader(true));

        try {
            const followPeopleResp = await getFollowPeople();
            setFollowPeopleList(followPeopleResp.data.data);
        } catch (error: any) {
            const errors = parseErrors(error.response?.data?.error);

            for (let i = 0; i < errors.length; i += 1) {
            messageContext.instance?.open({
                type: "error",
                content: errors[i],
            });
            }
        }

        dispatch(setLoader(false));
    };

    const toggleSearchSection = () => setSearchVisible(!searchVisible);

    const addUserHandler = async (userUID: string) => {
        await addFollowPeople(userUID);
        await fetchFollowPeople();
    };

    const removeUserHandler = async (userID: string) => {
        await removeFollowPeople(userID);
        await fetchFollowPeople();
    };

    return (
        <Row>
            <Col>
                <Row>
                    <h2>People to follow</h2>&nbsp;&nbsp;&nbsp;
                    {searchVisible 
                        ? <SearchSection
                            toggleSearch={toggleSearchSection}
                            onSelectHandler={async (val: string) => await addUserHandler(val)}
                            />
                        : <Row className="btn-container">
                            <Button type="primary" onClick={toggleSearchSection}>
                                <PlusCircleOutlined />
                                Add
                            </Button>
                          </Row>
                    }
                    
                </Row>
                <br />
                <Row className="btn-container">
                    {followPeopleList.length > 0
                        ? followPeopleList.map(fpItem => <Person {...fpItem} deleteHandler={removeUserHandler} />)
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </Row>
            </Col>
        </Row>
    );
}
