import { Button, Form, Input, Row, Switch, Tooltip } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { IUserExclusionData } from "../../types";
import parseErrors from "../../utils/parseErrors";
import { addExclusions } from "../../utils/api";

const CreateExclusion = () => {
    const navigate = useNavigate();
    const messageContext = useContext(MessageContext);
    const [exclusionData, setExclusionData] = useState<IUserExclusionData>({
        exclusion: "",
        reason: "",
    });
    const [form] = Form.useForm();

    const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
        setExclusionData({
        ...exclusionData,
        [name]: event.target.value,
        });
    };

    const goBack = () => {
        navigate(-1);
    };

    const createAdmin = async () => {
        try {
            await addExclusions(exclusionData);
            goBack();
        } catch (error: any) {
            console.log(error);
            const errors = parseErrors(error.response?.data?.error);

            for (let i = 0; i < errors.length; i += 1) {
                messageContext.instance?.open({
                type: "error",
                content: errors[i],
                });
            }
        }
    };

    const onChangeSwitch = (value: boolean) => {
        setExclusionData((prev) => {
        return {
            ...prev,
            notifications: value,
        };
        });
    };

    return (
        <div className="admin">
            <Row className="title">
                <Tooltip title="Back">
                <Button onClick={goBack} icon={<LeftOutlined />} />
                </Tooltip>

                <h1>Add Exclusion</h1>
            </Row>

            <Row>
                <Form
                initialValues={exclusionData}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                name="control-hooks"
                onFinish={createAdmin}
                >
                <Form.Item name="exclusion" label="Exclusion">
                    <Input value={exclusionData.exclusion} onChange={onChange("exclusion")} />
                </Form.Item>

                <Form.Item name="reason" label="Reason">
                    <Input value={exclusionData.reason} onChange={onChange("reason")} />
                </Form.Item>

                <Form.Item
                    className="btn-container"
                    wrapperCol={{ offset: 8, span: 16 }}
                >
                    <Button type="primary" htmlType="submit">
                    Add
                    </Button>
                </Form.Item>
                </Form>
            </Row>
        </div>
    );
};

export default CreateExclusion
