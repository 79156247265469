const parseErrors = (error: any) => {
  if (!error) {
    return ["Something went wrong..."];
  }

  if (typeof error === "string") {
    return [error];
  }

  const res = [];

  const valuesArr = Object.keys(error) || [];

  for (let i = 0; i < valuesArr.length; i += 1) {
    if (error[valuesArr[i]].msg) {
      res.push(`${valuesArr[i]}: ${error[valuesArr[i]].msg}`);
    }
  }

  return res;
};

export default parseErrors;
