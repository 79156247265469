import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { Card } from 'antd';


interface PersonProps {
    id: string;
    photoURL: string;
    username: string;
    displayName: string;
    deleteHandler: (userID: string) => {}
}

const Person = (props: PersonProps) => {
    return (
        <Card style={{ width: 200, height: 250, marginRight: 20 }} key={props.id}>
            <DeleteOutlined
              style={{
                display: "block", 
                textAlign: "right", 
                marginRight: "-8px", 
                marginTop: "-8px", 
                color: "red"}}
              onClick={() => props.deleteHandler(props.id)} />
            <center>
                <img src={props.photoURL} alt='profile' width={120} height={120} style={{borderRadius: 70}} />
                <p><b>{props.displayName}</b></p>
                <p style={{color: 'gray'}}>@{props.username}</p>
            </center>
        </Card>
    );
};

export default Person
