import { Button, Modal, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { Status } from "../../types";
import { statuses } from "../../utils/constants";
import parseErrors from "../../utils/parseErrors";
import { MessageContext } from "../../context/messageContext";
import { changeHypelistStatus } from "../../utils/api";

interface IChangeStatusModalProps {
  hypelistId: string;
  status: Status;
  setNeedRefresh?: (needRefresh: boolean) => void;
}

const ChangeStatusModal = ({
  status,
  hypelistId,
  setNeedRefresh,
}: IChangeStatusModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<Status>("ok");
  const messageContext = useContext(MessageContext);
  const statusList = [
    {
      value: statuses.OK.value,
      label: statuses.OK.label,
    },
    {
      value: statuses.REPORTED.value,
      label: statuses.REPORTED.label,
    },
    {
      value: statuses.BLOCKED.value,
      label: statuses.BLOCKED.label,
    },
  ];

  useEffect(() => {
    setSelectedStatus(status || "ok");
  }, [status]);

  const changeVisible = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (value: Status) => {
    setSelectedStatus(value);
  };

  const changeStatus = async () => {
    try {
      setIsLoading(true);

      await changeHypelistStatus(selectedStatus, hypelistId);
      changeVisible();

      if (setNeedRefresh) {
        setNeedRefresh(true);
      }

      setIsLoading(false);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <Button ghost type="primary" onClick={changeVisible}>
        Change status
      </Button>

      <Modal
        title="Change status"
        open={isOpen}
        onOk={changeStatus}
        onCancel={changeVisible}
        className="change-status-modal"
        confirmLoading={isLoading}
      >
        <Select
          onChange={handleChange}
          value={selectedStatus}
          options={statusList}
        />
      </Modal>
    </>
  );
};

export default ChangeStatusModal;
