import { LeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm, Row, Switch, Tooltip } from "antd";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { setLoader } from "../../store/actions/mainActions";
import { IUpdateAdmin } from "../../types";
import { deleteAdmin, getAdminById, updateAdmin } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";

const Admin = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const [adminData, setAdminData] = useState<IUpdateAdmin>({
    username: "",
    currentPassword: "",
    newPassword: "",
    email: "",
    phone: "",
    notifications: false,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    fetchAdmin();
  }, [userId]);

  const fetchAdmin = async () => {
    dispatch(setLoader(true));

    try {
      const admin = await getAdminById(userId ?? "");
      setAdminData({
        username: admin.data.data.username,
        currentPassword: "",
        newPassword: "",
        email: admin.data.data.email,
        phone: admin.data.data.phone,
        notifications: admin.data.data.notifications,
      });
      form.setFieldsValue(admin.data.data);
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setAdminData({
      ...adminData,
      [name]: event.target.value,
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const saveAdmin = async () => {
    dispatch(setLoader(true));

    try {
      await updateAdmin(userId ?? "", adminData);
      goBack();
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const onChangeSwitch = (value: boolean) => {
    setAdminData((prev) => {
      return {
        ...prev,
        notifications: value,
      };
    });
  };

  const handleDeleteAdmin = async () => {
    dispatch(setLoader(true));

    try {
      await deleteAdmin(userId ?? "");
      goBack();
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  return (
    <div className="admin">
      <Row className="title">
        <Tooltip title="Back">
          <Button onClick={goBack} icon={<LeftOutlined />} />
        </Tooltip>

        <h1>{adminData.username}</h1>
      </Row>

      <Row>
        <Form
          initialValues={adminData}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          name="control-hooks"
          onFinish={saveAdmin}
        >
          <Form.Item name="username" label="Username">
            <Input
              value={adminData.username}
              onChange={onChange("username")}
              disabled
            />
          </Form.Item>

          <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
            <Input value={adminData.email} onChange={onChange("email")} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                type: "string",
                pattern: new RegExp(
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                ),
                message: "Wrong phone format",
              },
            ]}
          >
            <Input value={adminData.phone} onChange={onChange("phone")} />
          </Form.Item>

          <Form.Item
            label="Current password"
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password
              value={adminData.currentPassword}
              onChange={onChange("currentPassword")}
            />
          </Form.Item>

          <Form.Item label="New password" name="newPassword">
            <Input.Password
              value={adminData.newPassword}
              onChange={onChange("newPassword")}
            />
          </Form.Item>

          <Form.Item label="Notifications" valuePropName="checked">
            <Switch
              checked={adminData.notifications}
              onChange={onChangeSwitch}
            />
          </Form.Item>

          <Form.Item
            className="btn-container"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Popconfirm
              title="Delete admin"
              description="Are you sure to delete admin?"
              onConfirm={handleDeleteAdmin}
              okText="Delete"
              cancelText="Cancel"
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Row>
    </div>
  );
};

export default Admin;
