import { Card, Form } from "antd";
import { GoogleLogin } from "@react-oauth/google";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { LogoIcon } from "../../components/icons";
import { MessageContext } from "../../context/messageContext";
import {
  setAuthenticated,
  setAuthResponseData,
} from "../../store/actions/authActions";
import { setLoader } from "../../store/actions/mainActions";
import { signIn } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";

const Signin = () => {
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);

  const onGoogleSigninSuccess = async (credential: string) => {
    dispatch(setLoader(true));

    try {
      const res = await signIn({ token: credential });
      dispatch(setAuthResponseData({ token: res.data.token }));
      dispatch(setAuthenticated(true));
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.errorMessage);
      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  return (
    <div className="signin">
      <Card>
        <Form
          name="signin"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
        >
          <div className="text-center">
            <LogoIcon />
            <h2>Sign in</h2>
          </div>
        </Form>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            if (credentialResponse.credential)
              onGoogleSigninSuccess(credentialResponse.credential);
            else {
              alert("Error with signin");
            }
          }}
          onError={() => {
            alert("Error with signin");
          }}
        />
        ;
      </Card>
    </div>
  );
};

export default Signin;
