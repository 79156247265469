import { LoadingOutlined } from "@ant-design/icons";

const Loader = () => {
  return (
    <div className="loader">
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
