import { Button, Form, Input, Modal, Row } from "antd";
import { useState } from "react";
import HypelistSearchBar from "../../components/HypelistSearchBar";

export default function EditHighlight({
  handleEditHighlight,
  highlightData,
  showModal,
  setShowModal,
}: {
  handleEditHighlight: (id: string, payload: any) => void;
  highlightData: any;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}) {
  const [selectedHypelistId, setSelectedHypelistId] = useState<string | null>(
    highlightData?.hypelist?.hypelistId
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleVideoChange = (event: any) => {
    setSelectedVideo(event.target.files[0]);
  };

  const handleOk = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const onFinish = async (values: any) => {
    handleEditHighlight(highlightData.id, {
      hypelistId: selectedHypelistId,
      ...values,
      file: selectedFile,
      video: selectedVideo,
    });
    setShowModal(false);
  };

  const onFinishFailed = () => {
    setShowModal(false);
    alert("Missing fields");
  };

  const onHypelistSelect = (hypelistId: string, hypelistName: string) => {
    setSelectedHypelistId(hypelistId);
  };

  return (
    <>
      <Modal
        title="Edit Highlight"
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div style={{ display: "flex" }}></div>
        <br />
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="highlightTitle"
            rules={[{ required: true, message: "Please input list title" }]}
          >
            <Input
              placeholder="Highlight Title"
              defaultValue={highlightData.highlightTitle}
            />
          </Form.Item>
          <Form.Item name="highlightSubtitle">
            <Input
              placeholder="Highlight Subtitle"
              defaultValue={highlightData.highlightSubTitle}
            />
          </Form.Item>
          <Form.Item name="externalLinkUrl">
            <Input
              placeholder="External URL"
              defaultValue={highlightData.externalLinkUrl}
            />
          </Form.Item>
          <Form.Item name="externalLinkTitle">
            <Input
              placeholder="External URL Text"
              defaultValue={highlightData.externalLinkTitle}
            />
          </Form.Item>
          <h3>Choose One Highlight Media Type:</h3>
          <h4>Background Image:</h4>
          <div>
            This image will be replaced by a thumbnail of the first frame of a
            video, if chosen. It's worth uploading one, in case video
            transcoding fails.
          </div>
          <Input
            type="file"
            placeholder="Background Image"
            onChange={handleFileChange}
          />
          <br />
          <h4>Video &lt;10s:</h4>
          <Input
            type="file"
            placeholder="Add video (<10s)"
            onChange={handleVideoChange}
          />
          <br />
          <h4>Link a Hypelist:</h4>
          <HypelistSearchBar onSelect={onHypelistSelect} />
          <br />
          {selectedHypelistId && (
            <Row>
              <div>{selectedHypelistId}</div>&nbsp;
              <Button
                type="primary"
                htmlType="button"
                onClick={() => setSelectedHypelistId(null)}
                title="Clear"
              >
                Clear
              </Button>
            </Row>
          )}
          <br />
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
