import { SET_METRICS_DATA } from "../constants/dashboardConstants";
import { CLEAR_STORE } from "../constants/mainConstants";

const initialState = {
  metrics: {
    hypelist: 0,
    user: 0,
  },
};

const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_METRICS_DATA:
      return {
        ...state,
        metrics: action.payload,
      };

    case CLEAR_STORE:
      return initialState;

    default:
      return state;
  }
};

export default dashboardReducer;
