import FollowPeople from "./FollowPeople";
import { useContext, useEffect, useState } from "react";
import { ICuratedList } from "../../types";
import {
  deleteCuratedList,
  getAllCuratedLists,
  moveCuratedList,
  createCuratedList,
  saveCuratedListHypelistOrder,
  deleteHypelistFromCuratedList,
  toggleListVisibility,
  editCuratedList,
  addHypelistToCuratedList,
  updateSettings,
} from "../../utils/api";
import { Row, Switch } from "antd";
import { MessageContext } from "../../context/messageContext";
import parseErrors from "../../utils/parseErrors";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/actions/mainActions";
import CreateCuratedList from "./CreateCuratedList";
import CuratedListTable from "./CuratedListTable";
import HighlightsTable from "./HighlightsTable";
import HypelistTemplateTable from "./HypelistTemplateTable";
import { fetchSettings } from "../../store/actions/settingsActions";
import { AppDispatch, RootState } from "../../store";

const RANDOMIZE_SETTING = "randomizeCuratedLists";

const Discover = () => {
  const [curatedLists, setCuratedLists] = useState<ICuratedList[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const settings = useSelector(
    (state: RootState) => state.settingsStore.settings
  );

  useEffect(() => {
    fetchCuratedLists();
    getSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMoveCuratedList = async (uid: string, order: number) => {
    await moveCuratedList(uid, order);
    fetchCuratedLists();
  };

  const handleDeleteCuratedList = async (uid: string) => {
    await deleteCuratedList(uid);
    fetchCuratedLists();
  };

  const handleToggleListVisibility = async (
    uid: string,
    visibility: boolean
  ) => {
    dispatch(setLoader(true));
    await toggleListVisibility(uid, visibility);
    fetchCuratedLists();
  };

  const handleToggleRandomizeSetting = async () => {
    dispatch(setLoader(true));
    const isRandom = settings[RANDOMIZE_SETTING];
    await updateSettings({
      [RANDOMIZE_SETTING]: !isRandom,
    });
    getSettings();
    dispatch(setLoader(false));
  };

  const handleCreateCuratedList = async (title: string, uids: string[]) => {
    dispatch(setLoader(true));
    await createCuratedList(title, uids);
    fetchCuratedLists();
    dispatch(setLoader(false));
  };

  const handleSaveItemOrder = async (
    hypelistId: string,
    curatedListId: string,
    ordering: number
  ) => {
    dispatch(setLoader(true));
    await saveCuratedListHypelistOrder(hypelistId, curatedListId, ordering);
    fetchCuratedLists();
  };

  const handleDeleteHypelistFromCuratedList = async (
    hypelistId: string,
    curatedListId: string
  ) => {
    dispatch(setLoader(true));
    await deleteHypelistFromCuratedList(hypelistId, curatedListId);
    fetchCuratedLists();
  };

  const handleAddHypelistToCuratedList = async (
    hypelistId: string,
    curatedListId: string
  ) => {
    dispatch(setLoader(true));
    await addHypelistToCuratedList(hypelistId, curatedListId);
    fetchCuratedLists();
  };

  const handleEditCuratedListTitle = async (
    curatedListId: string,
    title: string
  ) => {
    dispatch(setLoader(true));
    await editCuratedList(curatedListId, {
      title,
    });
    fetchCuratedLists();
  };

  const fetchCuratedLists = async () => {
    dispatch(setLoader(true));
    setCuratedLists([]);

    try {
      const curatedLists = await getAllCuratedLists();
      setCuratedLists(curatedLists.data.data);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const getSettings = async () => {
    try {
      await dispatch(fetchSettings());
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }
  };

  return (
    <div>
      <Row>
        <h1>Discover Screen</h1>
      </Row>
      <br /> <br />
      <HighlightsTable />
      <br /> <br />
      <HypelistTemplateTable />
      <br /> <br />
      <Row>
        <h2>Curated Lists</h2>&nbsp; &nbsp;
        <CreateCuratedList handleCreateCuratedList={handleCreateCuratedList} />
        &nbsp; &nbsp;<h3>Randomize List Order In App:</h3>&nbsp; &nbsp;
        <Switch
          value={settings[RANDOMIZE_SETTING]}
          defaultChecked
          onChange={handleToggleRandomizeSetting}
        />
      </Row>
      <br />
      {curatedLists.length > 0 && (
        <CuratedListTable
          curatedList={curatedLists[0]}
          handleToggleListVisibility={handleToggleListVisibility}
          handleMoveCuratedList={handleMoveCuratedList}
          handleDeleteCuratedList={handleDeleteCuratedList}
          handleSaveItemOrder={handleSaveItemOrder}
          handleDeleteItemFromCuratedList={handleDeleteHypelistFromCuratedList}
          handleEditCuratedListTitle={handleEditCuratedListTitle}
          handleAddHypelistToCuratedList={handleAddHypelistToCuratedList}
        />
      )}
      <br /> <br /> <br />
      <FollowPeople />
      <br /> <br /> <br />
      {curatedLists.slice(1).map((curatedList) => {
        return (
          <>
            <CuratedListTable
              key={curatedList.id}
              curatedList={curatedList}
              handleToggleListVisibility={handleToggleListVisibility}
              handleMoveCuratedList={handleMoveCuratedList}
              handleDeleteCuratedList={handleDeleteCuratedList}
              handleSaveItemOrder={handleSaveItemOrder}
              handleDeleteItemFromCuratedList={
                handleDeleteHypelistFromCuratedList
              }
              handleEditCuratedListTitle={handleEditCuratedListTitle}
              handleAddHypelistToCuratedList={handleAddHypelistToCuratedList}
            />
            <br /> <br /> <br />
          </>
        );
      })}
      <br /> <br /> <br />
    </div>
  );
};

export default Discover;
