import { Button, Row, Select } from "antd";
import { useState } from "react";
import { searchUsers } from "../../utils/api";
import type { SelectProps } from "antd/lib/select"


interface SearchSectionProps {
    toggleSearch: () => void;
    onSelectHandler: (userUID: string) => void
}

export default function SearchSection(props: SearchSectionProps) {
    const [data, setData] = useState<SelectProps['options']>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const handleSearch = async (newVal: string) => {
        if (newVal === null || newVal.trim() === '') {
            setSearchValue('');
            return;
        }

        setSearchValue(newVal);

        const users = await searchUsers(newVal);
        const userData = users.data.data;        

        const result: SelectProps['options'] = [];
        for(const user of (userData || [])) {
            result.push({
                value: user.id,
                label: `${user.displayName} (@${user.username})`,
            })
        }
        setData(result);
    };

    return (
        <Row>
            <Select
                placeholder='search for users'
                showSearch
                defaultActiveFirstOption={false}
                suffixIcon={null}
                onSearch={handleSearch}
                onChange={(newVal) => setSearchValue(newVal !== null ? newVal : '')}
                onClear={() => setSearchValue('')}
                onSelect={(val: string) => props.onSelectHandler(val)}
                filterOption={false}
                notFoundContent={null}
                style={{width: 330}}
                options={(data || []).map((d: any) => ({
                    value: d.value,
                    label: d.label,
                }))}
                value={searchValue}
            />
            &nbsp;&nbsp;&nbsp;
            <Button type="primary" onClick={props.toggleSearch}>Hide</Button>
        </Row>
    );
};
