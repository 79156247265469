import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Radio } from "antd";
import { useState } from "react";

export default function CreateHypelistTemplate({
  handleCreateTemplate,
}: {
  handleCreateTemplate: (payload: any) => void;
}) {
  const [open, setOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const onFinish = async (values: any) => {
    handleCreateTemplate({
      ...values,
      file: selectedFile,
    });
    setOpen(false);
  };

  const onFinishFailed = () => {
    setOpen(false);
    alert("Missing fields");
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <PlusCircleOutlined />
        New Hypelist Template
      </Button>
      <Modal
        title="Create Template"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <div style={{ display: "flex" }}></div>
        <br />
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input template name" }]}
          >
            <Input placeholder="Template Name" />
          </Form.Item>
          <Form.Item name="description">
            <Input placeholder="Template Description" />
          </Form.Item>
          <Form.Item label="List Type" name="type">
            <Radio.Group>
              <Radio value="Places"> Places </Radio>
              <Radio value="Entertainment"> Entertainment </Radio>
              <Radio value="Products"> Products </Radio>
              <Radio value="Fitness"> Fitness </Radio>
              <Radio value="Digital"> Digital </Radio>
              <Radio value="Other"> Other </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="backgroundColor">
            <Input placeholder="Background Color Hex Code" />
          </Form.Item>
          <h4>Background Image:</h4>
          <Input
            type="file"
            placeholder="Background Image"
            onChange={handleFileChange}
          />
          <br />
          <br />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
