import { Dispatch } from "@reduxjs/toolkit";
import { SET_SETTINGS } from "../constants/settingsConstants";
import { getSettings } from "../../utils/api";

export const fetchSettings = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await getSettings();

    dispatch({
      type: SET_SETTINGS,
      payload: data.data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: SET_SETTINGS,
      payload: {},
    });

    return false;
  }
};
