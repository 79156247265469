import { CLEAR_STORE } from "../constants/mainConstants";
import { SET_PAGINATION } from "../constants/usersConstants";

const initialState = {
  pagination: {
    offset: 0,
  },
};

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          [action.payload.name]: action.payload.value,
        },
      };

    case CLEAR_STORE:
      return initialState;

    default:
      return state;
  }
};

export default usersReducer;
