import { Button, Input, Row, Space, Table } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import parseErrors from "../../utils/parseErrors";
import { IUserExclusion } from "../../types";
import { getAllExclusions, searchExclusions } from "../../utils/api";

const Exclusions = () => {
    const [pagination, setPagination] = useState({
        total: 0,
        currentPage: 1,
        offset: 0,
        rowsPerPage: 10,
    });
    const navigate = useNavigate();
    const messageContext = useContext(MessageContext);
    const [exclusionsList, setExclusionsList] = useState<IUserExclusion[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Exclusion",
            dataIndex: "exclusion",
            key: "exclusion",
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            key: "createdAt",
        },
    ];

    useEffect(() => {
        fetchExclusions(pagination.offset);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchExclusions = async (offset: number = 0) => {
        setIsLoading(true);

        try {
            const exclusions = await getAllExclusions(offset);
            setExclusionsList(exclusions.data.data.data);

          setPagination({
              ...pagination,
              total: exclusions.data.data.total,
              offset: offset,
              currentPage: offset / pagination.rowsPerPage + 1 || 1,
          });
        } catch (error: any) {
          const errors = parseErrors(error.response?.data?.error);

          for (let i = 0; i < errors.length; i += 1) {
              messageContext.instance?.open({
              type: "error",
              content: errors[i],
              });
          }
        }

        setIsLoading(false);
    };

    const onPaginationChange = (page: number) => {
        fetchExclusions(page * pagination.rowsPerPage - pagination.rowsPerPage);
    };

    const onSearch = async (value: string) => {
      if (!value) {
        return;
      }

      setIsLoading(true);
      try {
        const searchResults = await searchExclusions(value);
        setExclusionsList(searchResults.data.data.data);

        setPagination({
          ...pagination,
          total: searchResults.data.data.total,
          offset: 0,
          currentPage: 1,
        });
      } catch (error: any) {
        const errors = parseErrors(error.response?.data?.error);

        for (let i = 0; i < errors.length; i += 1) {
          messageContext.instance?.open({
            type: "error",
            content: errors[i],
          });
        }
      }

      setIsLoading(false);
    };

    const resetSearch = () => {
        fetchExclusions();
        setSearchValue("");
    };

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    };

    const addExclusion = () => {
        navigate(`/exclusions/add`);
    };

    return (
      <div className="users">
        <Row>
          <h1>Exclusions</h1>
        </Row>
        <Row>
          <Button type="primary" onClick={addExclusion}>
            <PlusCircleOutlined />
            Add
          </Button>
        </Row>
        <Row>
          <Space direction="horizontal">
            <Input.Search
              size="large"
              placeholder="Search..."
              onSearch={onSearch}
              loading={isLoading}
              value={searchValue}
              onChange={onChangeSearch}
            />
            <Button size="large" onClick={resetSearch}>
              Reset
            </Button>
          </Space>

          <Table
            rowKey="id"
            columns={columns}
            dataSource={exclusionsList}
            pagination={{
              current: pagination.currentPage,
              total: pagination.total,
              showSizeChanger: false,
              onChange: onPaginationChange,
            }}
            loading={isLoading}
          />
        </Row>
      </div>
    );
}

export default Exclusions;
