import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./store";
import { Provider } from "react-redux";
import "normalize.css";
import "./styles/index.scss";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { MessageContextProvider } from "./context/messageContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getGoogleClientId } from "./utils/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const googleClientId = getGoogleClientId();
root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "HK Grotesk",
          },
          components: {
            Button: {},
          },
        }}
      >
        <BrowserRouter>
          <MessageContextProvider>
            <App />
          </MessageContextProvider>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </GoogleOAuthProvider>
);
