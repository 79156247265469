import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import { clearStore } from "./actions/mainActions";
import rootReducer from "./reducers";

export const store = configureStore({
  reducer: rootReducer,
});

const interceptor = (store: any) => {
  axios.interceptors.request.use(function (config) {
    if (config.headers?.uid) {
      return config;
    }

    const sessionData = window.sessionStorage.getItem("authResponse");
    let authResponse: any = {};
    try {
      authResponse = JSON.parse(sessionData ?? "{}");
    } catch (error) {}
    const token = authResponse.token || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      // Global error handler for unauthorized requests
      if (error.response.status === 403 || error.response.status === 401) {
        store.dispatch(clearStore());
      }
      return Promise.reject(error);
    }
  );
};

interceptor(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
