import { Button, Input, Row, Space, Table } from "antd";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IHypelistAdmin } from "../../types";

import { MessageContext } from "../../context/messageContext";
import parseErrors from "../../utils/parseErrors";
import { getAllAvailableHypelists, searchAllHypelists } from "../../utils/api";

const Hypelists = () => {
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    offset: 0,
    rowsPerPage: 10,
  });
  const messageContext = useContext(MessageContext);
  const [hypelistArray, setHypelistArray] = useState<IHypelistAdmin[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (_: any, record: IHypelistAdmin) => (
        <Link
            to={`/hypelist/${record.id}`}
            state={{ url: process.env.NODE_ENV === "development" ? record.shareLink.replace("https://endpoints.hypelist.com", "http://localhost:8080"): record.shareLink, userId: "" }}
            >
            {record.id}
        </Link>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Username",
      key: "username",
      render: (_: any, record: IHypelistAdmin) => <Link to={`/users/${record.userId}`}>{record.userName}</Link>
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
    }
  ];

    useEffect(() => {
    fetchAllHypelists(pagination.offset);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllHypelists = async (offset: number = 0) => {
    setIsLoading(true);

    try {
      const users = await getAllAvailableHypelists(offset);
      setHypelistArray(users.data.data.data);

      setPagination({
        ...pagination,
        total: users.data.data.total,
        offset: offset,
        currentPage: offset / pagination.rowsPerPage + 1 || 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const onPaginationChange = (page: number) => {
    fetchAllHypelists(page * pagination.rowsPerPage - pagination.rowsPerPage);
  };

  const onSearch = async (value: string) => {
    if (!value) {
      return;
    }

    setIsLoading(true);
    try {
      const allHypelists = await searchAllHypelists(value);
      setHypelistArray(allHypelists.data.data);

      setPagination({
        ...pagination,
        total: allHypelists.data.data.length,
        offset: 0,
        currentPage: 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const resetSearch = () => {
    fetchAllHypelists();
    setSearchValue("");
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

    return (
    <div className="hypelists">
      <Row>
        <h1>Hypelists</h1>
      </Row>
      <Row>
        <Space direction="horizontal">
          <Input.Search
            size="large"
            placeholder="Search..."
            onSearch={onSearch}
            loading={isLoading}
            value={searchValue}
            onChange={onChangeSearch}
          />
          <Button size="large" onClick={resetSearch}>
            Reset
          </Button>
        </Space>

        <Table
          rowKey="id"
          columns={columns}
          dataSource={hypelistArray}
          pagination={{
            current: pagination.currentPage,
            total: pagination.total,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
          loading={isLoading}
        />
      </Row>
    </div>
    );
};

export default Hypelists;
