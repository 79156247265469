import { ISigninResponseData } from "../../types";
import {
  SET_AUTHENTICATED,
  SET_AUTH_DATA,
  SET_AUTH_RESPONSE,
} from "../constants/authConstants";

export const setAuthData = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => {
  return {
    type: SET_AUTH_DATA,
    payload: {
      name,
      value,
    },
  };
};

export const setAuthenticated = (isAuthenticated: boolean) => {
  return {
    type: SET_AUTHENTICATED,
    payload: isAuthenticated,
  };
};

export const setAuthResponseData = (authResponseData: ISigninResponseData) => {
  return {
    type: SET_AUTH_RESPONSE,
    payload: authResponseData,
  };
};
