import {
  SET_AUTHENTICATED,
  SET_AUTH_DATA,
  SET_AUTH_RESPONSE,
} from "../constants/authConstants";
import { CLEAR_STORE } from "../constants/mainConstants";

const initialState = {
  authData: {
    username: "",
    password: "",
  },
  authResponse: {
    updatedAt: "",
    email: "",
    password: "",
    uid: "",
    username: "",
    notifications: false,
    createdAt: "",
    phone: "",
  },
  isAuthenticated: false,
};

const authReducer = (
  state = initialState,
  action: any
): typeof initialState => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return {
        ...state,
        authData: {
          ...state.authData,
          [action.payload.name]: action.payload.value,
        },
      };

    case SET_AUTHENTICATED:
      window.sessionStorage.setItem("isAuthenticated", String(action.payload));

      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case SET_AUTH_RESPONSE:
      window.sessionStorage.setItem(
        "authResponse",
        JSON.stringify(action.payload)
      );

      return {
        ...state,
        authResponse: action.payload,
      };

    case CLEAR_STORE:
      window.sessionStorage.removeItem("isAuthenticated");
      window.sessionStorage.removeItem("authResponse");
      return initialState;

    default:
      return state;
  }
};

export default authReducer;
