import { Tag } from "antd";
import { Status } from "../../types";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";

interface ILabelProps {
  text: Status;
}

const Label = ({ text }: ILabelProps) => {
  const [color, setColor] = useState("success");
  const [icon, setIcon] = useState(<CheckCircleOutlined />);

  useEffect(() => {
    getIcon(text);
  }, [text]);

  const getIcon = (status: Status) => {
    switch (status) {
      case "ok":
        setColor("success");
        setIcon(<CheckCircleOutlined />);
        break;

      case "reported":
        setColor("warning");
        setIcon(<ExclamationCircleOutlined />);
        break;

      case "blocked":
        setColor("error");
        setIcon(<CloseCircleOutlined />);
        break;

      default:
        setColor("success");
        setIcon(<CheckCircleOutlined />);
        break;
    }
  };

  return (
    <Tag icon={icon} color={color} className={`label`}>
      {text}
    </Tag>
  );
};

export default Label;
