import { Button, Input, Row, Space, Table } from "antd";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { IReportedListItem } from "../../types";
import { getReportedList, searchReportedList } from "../../utils/api";
import parseErrors from "../../utils/parseErrors";

const Reported = () => {
  const messageContext = useContext(MessageContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [reportedList, setReportedList] = useState<IReportedListItem[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    offset: 0,
    rowsPerPage: 10,
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Hypelist ID",
      dataIndex: "hypelistId",
      key: "hypelistId",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];

  useEffect(() => {
    fetchReportedList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReportedList = async (offset: number = pagination.offset) => {
    setIsLoading(true);

    try {
      const reported = await getReportedList(offset);

      setReportedList(reported.data.data.data);

      setPagination({
        ...pagination,
        total: reported.data.data.total,
        offset: offset,
        currentPage: offset / pagination.rowsPerPage + 1 || 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const onPaginationChange = (page: number) => {
    fetchReportedList(page * pagination.rowsPerPage - pagination.rowsPerPage);
  };

  const onSearch = async (value: string) => {
    if (!value) {
      return;
    }

    setIsLoading(true);
    try {
      const foundedUsers = await searchReportedList(value);
      setReportedList(foundedUsers.data.data.data);

      setPagination({
        ...pagination,
        total: foundedUsers.data.data.total,
        offset: 0,
        currentPage: 1,
      });
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: "error",
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const resetSearch = () => {
    fetchReportedList();
    setSearchValue("");
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onRowClick = (repotedItem: IReportedListItem) => {
    navigate(`/hypelist/${repotedItem.hypelistId}`, {
      state: {
        url: repotedItem.hypelistURL,
      },
    });
  };

  return (
    <div className="reported">
      <Row>
        <h1>Reported</h1>
      </Row>

      <Row>
        <Space direction="horizontal">
          <Input.Search
            size="large"
            placeholder="Search..."
            onSearch={onSearch}
            loading={isLoading}
            value={searchValue}
            onChange={onChangeSearch}
          />
          <Button size="large" onClick={resetSearch}>
            Reset
          </Button>
        </Space>

        <Table
          onRow={(record: IReportedListItem) => {
            return {
              onClick: () => onRowClick(record),
            };
          }}
          rowKey="hypelistId"
          columns={columns}
          dataSource={reportedList}
          pagination={{
            current: pagination.currentPage,
            total: pagination.total,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
          loading={isLoading}
        />
      </Row>
    </div>
  );
};

export default Reported;
